{
    "menu": {
        "home": "होम",
        "reports": "रिपोर्ट्स (Reports)",
        "tasks": "कार्य",
        "complaints": "शिकायतें",
        "vendors": "वेंडर्स",
        "employees": "कर्मचारी",
        "attendance": "उपस्थिति",
        "serviceRequest": "सर्विस रिक्वेस्ट",
        "catalog": "प्रोडक्ट कैटलॉग"
    },
    "general": {
        "service": "सर्विस",
        "category": "कॅटेगरी",
        "description": "डिस्क्रिप्शन",
        "assignee": "असाइन",
        "status":"स्टेटस",
        "comment":"कमेंट्स",
        "documents":"डॉक्युमेंट्स",
        "edit":"बदलाव करे",
        "delete":"डिलीट करे",
        "create":"क्रिएट करे",
        "subTask":"सब टास्क",
        "staff":"कर्मचारी",
        "inspaccokam":"Inspacco Key Account Manager",
        "servicevisitschedule":"Service Visit Schedule",
        "comments":"Comments"
    }
}