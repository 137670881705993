import React from "react";

import Layout from "./components/Layout";

export default function App() {
  return (
    <div>
      <div className="w-screen">
        <Layout />
      </div>
    </div>
  );
}
